<template>
    <CommonLayoutsVue layoutContentStyle="margin-top: 10rem;">
        <template v-slot:child>
            <div class="centered-container small-container">

                <div class="header-container text-center">
                    <!-- <span class="span-item">
                            Welcome to aipplicant
                        </span> -->
                    <h2 style="font-size: clamp(1.3rem,3vw,1.5rem);" class="centered-header">{{ userData.userName }}</h2>
                </div>

                <b-row class="centered-content">

                    <div class="col-12 para-content">
                        <div class="para-title">
                            {{ $gettext("CreateVisitingCard_paragraph") }}</div>
                    </div>
                </b-row>
                <div>
                    <b-button @click="goNext" class="btn full-width-btn" variant="primary" size="sm">
                        {{ $gettext("Create_visiting_card") }}
                        <!-- <router-link to="personal-details">Create visiting card</router-link> -->
                    </b-button>
                </div>
            </div>
        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'

export default {
    name: "CreateVisitingCard",
    components: { CommonLayoutsVue },
    data() {
        return {
            userData: null,
        }
    },
    methods: {
        goNext() {
            this.$router.push({ path: 'personal-details' })
        }

    },
    beforeMount() {
        this.userData = this.$store.getters.getLoggedUser
        // console.log(this.userData);
    }

}
</script>

<style></style>